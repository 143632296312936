/**
 * 防抖方法
 * @param fnName
 * @param time
 * @returns {function(): void}
 * @constructor
 */
export const onDebounce = (func, wait, immediate) => {
    let timeout

    return function(...args) {
        let context = this
        if (timeout) clearTimeout(timeout)

        if (immediate) {
            let callNow = !timeout
            timeout = setTimeout(function() {
                timeout = null
            }, wait)
            if (callNow) func.apply(context, args)
        } else {
            timeout = setTimeout(function() {
                func.apply(context, args)
            }, wait)
        }
    }
}

/**
 *  验证手机号
 * @param phone
 * @returns {boolean}
 */
export const validatePhone = (phone) => {
    let phoneRe = /^1(3|4|5|6|7|8|9)\d{9}$/;
    return phoneRe.test(phone);
}

// /**
//  *  验证邮箱
//  * @param email
//  * @returns {boolean}
//  */
// export const validateEmail = (email) => {
//     let emailRe = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
//     return emailRe.test(email);
// }

export const isMobile = () => {
    let device = ''
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if(flag === null) {
        device = 'PC'
    }else {
        if(flag == 'iPhone,iPhone'){
            device = 'IOS'
        }
        if(flag == 'Android,Android'){
            device = 'ANDROID'
        }
        if(flag == 'iPad,iPad'){
            device = 'IOS'
        }
    }
    return device
}

/**
 * 将Base64 转成文件
 * @param dataurl
 * @param filename
 * @returns {File}
 */
export const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

/**
 * 提取设备分辨率
 * @param obj
 * @returns {*}
 */
export const extractDevice = (obj) => {
    return obj.join().replace(/[^0-9]/ig,",").split(',')
}