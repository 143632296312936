<template>
    <div id="app">
        <router-view v-if="deviceId !== 'PC'" />
<!--        <div class="screen-orientation" v-if="screen">请竖屏使用，并且关闭手机旋转！</div>-->
        <div class="screen-orientation" v-if="deviceId === 'PC'">请使用移动设备登录！</div>
    </div>
</template>
<script>
import { isMobile } from '@/utils/tools'
export default {
    name: 'App',
    data(){
        return {
          // screen:false,
          deviceId:'',
        }
    },
    created () {
        this.deviceId = isMobile()
    },
    mounted() {
        this.device();
        window.onresize = () => {
            return (() => {
                this.device();
            })()
        };
        /**
         * 禁止双指放大
         */
        document.documentElement.addEventListener('touchstart', function (event) {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        }, false);
        /**
         * 禁止双击放大
         * @type {number}
         */
        let lastTouchEnd = 0;
        document.documentElement.addEventListener('touchend', function (event) {
            let now = Date.now();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }
            lastTouchEnd = now;
        }, false);
        document.addEventListener('gesturestart', function(event) {
            event.preventDefault();
        });
        document.addEventListener('dblclick', function (event) {
            event.preventDefault();
        })
        // /**
        //  * 判断横屏
        //  */
        // window.onorientationchange = () => {
        //     if(window.orientation==90||window.orientation==-90){
        //         this.screen = true
        //     }else{
        //         this.screen = false
        //     }
        //   }
    },
    methods:{
        device(){
            let deviceWidth = document.documentElement.clientWidth;
            if(deviceWidth > 540) deviceWidth = 540;
            if(window.innerWidth>window.innerHeight){
                document.documentElement.style.fontSize = deviceWidth / 11.36 + 'px';
            }else{
                document.documentElement.style.fontSize = deviceWidth / 7.5 + 'px';
            }

        },
    }
}
</script>
<style lang="less">
html,body,#app {height: 100%; margin: 0; padding: 0; font-size: 14px; font-family: 'Avenir',Helvetica,Arial,sans-serif;background: #2b3242}
.screen-orientation{
    position: fixed;
    z-index: 2000;
    left:0px;
    top:0px;
    font-size: .2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
    background: #e1e1e1;
}
</style>
