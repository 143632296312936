import Vue from 'vue';
import VueRouter from 'vue-router';
// import Cookies from "js-cookie";
const Index = () => import('@/views/Index/index.vue');
const Content = () => import('@/views/content/content.vue');
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
    },
    {
        path: '/content',
        name: 'Content',
        component: Content,
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = '禹步信息科技'
    if (to.matched.length === 0) { //匹配前往的路由不存在
        from.name ? next({
            name: from.name
        }) : next('/'); //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到首页
    } else {
        next()
    }
});
export default router
